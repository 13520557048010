import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Button from '../components/Button';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import HomeImage from '../svg/HomeImage';
import ArrowScroll from '../svg/arrowScroll';
import LogoLetras from '../svg/LogoLetras';
import Video from '../components/videodesktop';
import VideoMovil from '../components/videoMovil';
import CustomReactShare from '../components/CustonShare';

export default () => (
  <Layout>

<div/>
    <SplitSection
      id="services"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <div className="w-64">
            <LogoLetras />
          </div>
          <p className="mt-8 text-xl font-medium leading-relaxed text-justify">
          Aplicación móvil que busca apoyar el trabajo de los fonoaudiólogos y fonoaudiólogas a través de la automatización del Test de Articulación a la Repetición.
          </p>
          <div className="mt-8">
            <h4 className="text-2xl font-semibold leading-tight">Comparte nuestra idea</h4>
            <div className="mt-4">
              <CustomReactShare />
            </div>
          </div>
        </div>
      }
      secondarySlot={
        <div>
          <div className="hidden mx-auto md:flex">
            <Video
              videoSrcURL="https://www.youtube.com/embed/8SMLhoTa8Jw"
              videoTitle="Video Comercial EufoniApp"
            />
          </div>
          <div className="md:hidden mx-auto">
            <VideoMovil
              videoSrcURL="https://www.youtube.com/embed/8SMLhoTa8Jw"
              videoTitle="Video Comercial EufoniApp"
            />
          </div>
        </div>
      }
    />
    {/*
    <section id="index" className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:w-1/2">
          <div className="flex flex-row">

            <div>
              <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">Eufoni</h1>
            </div>
            <div>
              <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">App</h1>
            </div>

            <div className="w-54">
              <LogoLetras />
            </div>
          </div>
          <p className="text-xl text-center lg:text-2xl mt-2 font-light font-semibold">
            Tu solución rápida y efectiva a pruebas de fonoaudiología.
          </p>
          <div>
            <p className="mt-8 md:mt-12">
              <AnchorLink href="#services">
                <Button size="lg">Ver Video</Button>
              </AnchorLink>
            </p>
          </div>
          <p className="mt-4 text-center hidden md:block text-gray-600">
            Pitch presentado en nuestros inicios
          </p>
        </div>
        <div className="lg:w-1/2">
          <HomeImage />
        </div>
      </div>
      <div className="container mx-auto mt-5 px-8 flex">
        <div className="mx-auto">
          <ArrowScroll />
        </div>
      </div>
    </section>
    */}
    {/*
    <section id="features" className="py-20 lg:pb-40 lg:pt-20">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Main Features</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Service One</p>
              <p className="mt-4">
                An enim nullam tempor gravida donec enim ipsum blandit porta justo integer odio
                velna vitae auctor integer.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Service Two</p>
              <p className="mt-4">
                An enim nullam tempor gravida donec enim ipsum blandit porta justo integer odio
                velna vitae auctor integer.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Service Three</p>
              <p className="mt-4">
                An enim nullam tempor gravida donec enim ipsum blandit porta justo integer odio
                velna vitae auctor integer.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>
    */}
    <div className="my-16" />

    {/*
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Design And Plan Your Business Growth Steps
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Once the market analysis process is completed our staff will search for opportunities
            that are in reach
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Search For Performance Optimization
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            With all the information in place you will be presented with an action plan that your
            company needs to follow
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Our customers get results</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
        </div>
      </div>
    </section>
    <section id="testimonials" className="py-20 lg:py-40">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">What customers are saying</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map(customer => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section>
    <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">Ready to grow your business?</h3>
      <p className="mt-8 text-xl font-light">
        Quis lectus nulla at volutpat diam ut. Enim lobortis scelerisque fermentum dui faucibus in.
      </p>
      <p className="mt-8">
        <Button size="xl">Get Started Now</Button>
      </p>
    </section>
          */}
  </Layout>
);
