import React from 'react';

const VideoMovil = ({ videoSrcURL, videoTitle, ...props }) => (
  <div className="-ml-8">
    <iframe
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
      width="300"
      height="200"
    />
  </div>
);

export default VideoMovil;
