import { css } from 'emotion';
import PropTypes from 'prop-types';
import React from 'react';
import {
  ShareButtonRectangle,
  ShareBlockStandard,
  ShareButtonRoundSquare,
  ShareButtonCircle
} from 'react-custom-share';
import FaFacebook from '../svg/LogoFacebookBlanco';
import FaLinkedin from '../svg/LogoLinkedinBlanco';
import FaEmail from '../svg/LogoEmailBlanco';

const CustomReactShare = props => {
  const { url, title, excerpt } = props;

  const customStyles = css`
    border-radius: 50% 0 50% 0;
    margin: 0 10px;
    flex-basis: 60px;
    height: 60px;
    flex-grow: 0;
    max-width: 80%;
  `;
  const shareBlockProps = {
    url: url,
    button: ShareButtonRoundSquare,
    buttons: [
      { network: 'Facebook', icon: FaFacebook },
      { network: 'Linkedin', icon: FaLinkedin },
      { network: 'Email', icon: FaEmail }
    ],
    text: title,
    longtext: excerpt,
    buttonCustomClassName: customStyles
  };

  return <ShareBlockStandard {...shareBlockProps} />;
};

CustomReactShare.PropTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  excerpt: PropTypes.string
};

CustomReactShare.defaultProps = {
  url: 'https://eufoniapp.feriadesoftware.cl',
  title: 'EufoniApp - Bit As One',
  excerpt: 'Tu solución rápida y efectiva a pruebas de fonoaudiología'
};

export default CustomReactShare;
