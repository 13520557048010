import React from 'react';
import LogoFacebook from '../../svg/Logofacebook';
import LogoInstagram from '../../svg/LogoInstagram';
import LogoMail from '../../svg/LogoMail';
import LogoBAO from '../../svg/LogoBAO';
import LogoBAO2 from '../../svg/LogoBAO2';
import LogoFesw from '../../svg/LogoFesw';
import LogoUSM from '../../svg/LogoUSM';
import LogoFacebookFooter from '../../svg/LogoFacebookFooter';
import LogoInstagramFooter from '../../svg/LogoInstagramFooter';
import LogoMailFooter from '../../svg/LogoMailFooter';
import { Link } from 'gatsby';

const Footer = () => (
  <footer className="container mx-auto px-3 mt-48 mb-8 text-gray-800">
    <div className="md:flex -mx-3 ">
      <div className="flex-1 flex-row px-4">
        <h2 className="text-lg text-center md:text-left md:ml-0 font-semibold">
          XVIII Feria de Software
        </h2>
        <div className="flex">
          <div className="flex py-8 mx-10">
            <Link to="http://www.feriadesoftware.cl/" target="blank">
              <LogoFesw />
            </Link>
          </div>
          <div className="flex py-4 mx-10">
            <Link to="https://usm.cl/" target="blank">
              <LogoUSM />
            </Link>
          </div>
        </div>
        <div>
          <h4 className="mt-5 text-center md:text-left ">
            Universidad Técnica Federico Santa María
          </h4>
        </div>
        <div className="font-weight-bold text-center md:text-left">Santiago, RM - Chile</div>
        <div className="container">
          <div className="mt-4 hidden md:flex">
            <p>© 2020</p>
          </div>
        </div>
      </div>

      <div className="flex-1 flex-col items-center hidden md:block">
        {/*<div className="px-3 mx-6">
          <Link to="https://www.facebook.com/EufoniApp/" target="blank">
            <LogoFacebook />
          </Link>
        </div>
        <div className="px-3 mx-6">
          <Link to="https://www.instagram.com/eufoniapp/" target="blank">
            <LogoInstagram />
          </Link>
        </div>
        <div className="px-3 mx-6">
          <a href="mailto:eufoniapp@gmail.com">
            <LogoMail />
          </a>
        </div>*/}
        <h2 className="text-lg font-semibold text-center">Links</h2>
        <ul className="links mt-4 text-xl leading-loose text-center">
          <li>
            <Link to="/aplicacion">¿Qué es?</Link>
          </li>
          <li>
            <Link to="/nosotros">Nosotros</Link>
          </li>
          <li>
            <Link to="/contacto">Contacto</Link>
          </li>
        </ul>
      </div>
      <div className="flex-1 pt-10 flex-col md:ml-0 md:py-0">
        <h3 className="text-lg text-center md:text-center font-semibold">Desarrollado por</h3>
        
        <div className="flex ml-20 md:ml-10 md:items-center md:mt-4 mt-4">
         <div className="flex md:mx-10 w-56">
            <Link to="https://www.facebook.com/EufoniApp/" target="blank">
              <LogoBAO2 />
            </Link>
          </div>
        </div>
        
        
        <div className="flex ml-16 md:ml-10">
          <div className="flex m-8 w-6  md:block md:hidden">
            <Link to="https://www.facebook.com/EufoniApp/" target="blank">
              <LogoFacebookFooter />
            </Link>
          </div>
          <div className="flex m-8 w-6  md:block md:hidden">
            <Link to="https://www.instagram.com/eufoniapp/" target="blank">
              <LogoInstagramFooter />
            </Link>
          </div>
          <div className="flex m-8 w-6  md:block md:hidden ">
            <a href="mailto:eufoniapp@gmail.com">
              <LogoMailFooter />
            </a>
          </div>
        </div>

        {/*
        <div className=" flex flex-row ml-20 md:hidden mt-8">
          <div className="flex w-6 ">
            <LogoMailFooter />
          </div>
          <div className="flex ml-4">
            <a href="mailto:bitasone@gmail.com">BitAsOne@gmail.com</a>
          </div>
        </div>
        */}

        <div className="md:hidden mt-10">
          <h3 className="text-lg text-center">© 2020</h3>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
