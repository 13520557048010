import React from 'react';
import { Link } from 'gatsby';
import LogoIcon from '../../svg/LogoIcon';
import LogoFacebook from '../../svg/Logofacebook';
import LogoInstagram from '../../svg/LogoInstagram';
import LogoMail from '../../svg/LogoMail';
import '../../css/customStyle.css'

const Header = () => (
  <header className="sticky top-0 bg-white shadow">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center">
        <div className="w-40 mr-3">
          <Link to="/">
            <LogoIcon />
          </Link>
        </div>
      </div>
      <div className="links flex mt-4 sm:mt-0">
        <Link className="text-lg px-4" to="/aplicacion">
          ¿Qué es EufoniApp?
        </Link>
        <Link className="text-lg px-4" to="/nosotros">
          ¿Quiénes somos?
        </Link>
        <Link className="text-lg px-4" to="/contacto">
          Contacto
        </Link>
      </div>
      <div className="flex mt-4 sm:mt-0 hidden md:flex">
        <div className="px-3">
          <a href="https://www.facebook.com/EufoniApp/" target="blank">
            <LogoFacebook />
          </a>
        </div>
        <div className="px-3">
          <a href="https://www.instagram.com/eufoniapp/" target="blank">
            <LogoInstagram />
          </a>
        </div>
        <div className="px-3">
          <a href="mailto:eufoniapp@gmail.com">
            <LogoMail />
          </a>
        </div>
        {/*
        <Button className="text-sm">Start Free Trial</Button>
        */}
      </div>
    </div>
  </header>
);

export default Header;
